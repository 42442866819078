export default function (Alpine) {
    Alpine.magic('scrollTo', (el, { Alpine }) => sectionId => {
        let section = sectionId ? document.querySelector(sectionId) : document.querySelector(el.getAttribute('href'))
        let currentScrollPosition = document.documentElement.scrollTop
        let sectionOffsetToViewport = section.getBoundingClientRect().top
        let sectionTopPosition = sectionOffsetToViewport + currentScrollPosition
        let mobileHeader = document.querySelector("#mobile-header")
        let desktopHeader = document.querySelector("#desktop-header")
        let pageNavigation = document.querySelector(".pageNavigation")
        let headerHeight = 0
        if (window.getComputedStyle(desktopHeader).display === "none") {
            headerHeight = mobileHeader.getBoundingClientRect().height
        } else {
            headerHeight = desktopHeader.getBoundingClientRect().height
        }
        let scrollOffset = headerHeight
        if (pageNavigation) {
            scrollOffset += pageNavigation.getBoundingClientRect().height
        }
        window.scrollTo({
            top: sectionTopPosition - scrollOffset,
            behavior: 'smooth'
        })
    })
}
