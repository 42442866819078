import axios from "axios"

export default (event_uuid) => ({
    form: null,
    is_submitting: false,
    emailWasEntered: false,
    success: null,
    error: null,
    warning: null,
    event_uuid: event_uuid,
    email: '',
    terms: false,
    handleSubmit() {
        if (this.is_submitting) {
            return
        }

        this.is_submitting = true
        this.error = null
        this.success = null

        axios
            .post(this.$el.action, new FormData(this.$el))
            .then(response => {
                this.is_submitting = false
                this.success = response.data.status
                this.warning = !response.data.status

                if (this.success) {
                    this.event_uuid = ''
                    this.email = ''
                    this.terms = false
                } else {
                    this.warning = response.data.message
                }
            })
            .catch(error => {
                this.is_submitting = false
                // this.error = error.response.data
            })
    },
    resetFormData() {
        this.error = null
        this.warning = null
        this.success = null
        this.emailWasEntered = false
        this.event_uuid = ''
        this.email = ''
        this.terms = false
    },
    get emailIsValid() {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(this.email).toLowerCase());
    },
    get submitEnabled() {
        return !this.error && !this.is_submitting && this.terms == true && this.emailIsValid
    },
    get emailHasError() {
        if (this.email != '' && this.emailWasEntered && !this.emailIsValid) {
            return true
        }

        if (this.error && this.error.errorType == 'duplicate') {
            return true
        }

        return false
    },
})
