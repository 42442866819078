import axios from "axios"

export default () => ({
    eventUrl: '',
    passwordLabel: '',
    passwordPlaceholder: '',
    buttonText: '',
    password: '',
    passwordIncorrect: false,
    errorMessage: '',
    isSubmitting: false,
    talks: [],
    setTalks(initialTalks) {
        this.talks = initialTalks
    },
    talkHasFiles(talkId) {
        if (this.talks.length > 0) {
            return this.talks.find(talk => talk.id === talkId).files.length > 0
        }
        return false
    },
    talkFiles(talkId) {
        if (this.talks.length > 0) {
            return this.talks.find(talk => talk.id === talkId).files
        }
        return []
    },
    handleSubmit() {
        if (this.password && this.password != "") {
            this.isSubmitting = true

            axios
                .post(this.$el.action, { password: this.password })
                .then(response => {
                    this.isSubmitting = false
                    console.log(response.data)

                    if (response.data.error && response.data.error != "") {
                        this.passwordIncorrect = true
                        this.errorMessage = response.data.error
                        this.talks = []
                    } else if (response.data.data && response.data.data != "") {
                        this.passwordIncorrect = false
                        this.errorMessage = ""
                        this.talks = response.data.data
                    } else {
                        this.passwordIncorrect = false
                        this.errorMessage = ""
                        this.talks = []
                    }
                })
        }
    }
})
