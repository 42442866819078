import pym from 'pym.js'

export default (url) => ({
    url: url,
    init() {
        if (document.getElementById('registration-form')) {
            var pymParent = new pym.Parent('registration-form', this.url)
        }
    }
})
