import axios from "axios"

export default () => ({
    is_submitting: false,
    success: null,
    error: null,
    email: "",
    first_name: "",
    last_name: "",
    company: "",
    publisher: "",
    terms_accepted: false,
    handleSubmit() {
        if (this.is_submitting) {
            return
        }

        this.is_submitting = true
        this.error = null
        this.success = null

        axios
            .post(this.$el.action, this.getFormData())
            .then(response => {
                this.is_submitting = false
                this.success = response.data
            })
            .catch(error => {
                this.is_submitting = false
                this.error = error.response.data
            })
    },
    getFormData() {
        return {
            email: this.email,
            first_name: this.first_name,
            last_name: this.last_name,
            company: this.company,
            publisher: this.publisher,
            terms_accepted: this.terms_accepted,
        }
    },
    resetFormData() {
        this.error = null
        this.success = null
        this.email = ""
        this.first_name = ""
        this.last_name = ""
        this.company = ""
        this.publisher = ""
        this.terms_accepted = false
    },
    get emailIsValid() {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(this.email).toLowerCase());
    },
    get submitEnabled() {
        return !this.error && !this.is_submitting && this.terms_accepted == true && this.emailIsValid
    },
})
