export default () => ({
    filter: '',
    init() {
        this.$watch('filter', () => {
            this.$el.scrollIntoView({
                behavior: 'smooth',
            })
        })
    },
})
