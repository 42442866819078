import axios from "axios"

export default () => ({
    showForm: false,
    modalVisible: false,
    form: null,
    is_submitting: false,
    success: false,
    error: null,
    masters_enrolled: false,
    terms: false,
    init() {
        if (window.location.hash === '#application') {
            this.showForm = true
        }
    },
    handleSubmit() {
        if (this.is_submitting) {
            return
        }

        this.is_submitting = true
        this.error = null
        this.success = null

        axios
            .post(this.$refs.form.action, new FormData(this.$refs.form))
            .then(response => {
                this.is_submitting = false
                this.success = response.status === 200
                this.error = response.data.error || null

                if (this.success) {
                    this.showForm = false
                    this.modalVisible = true
                }

                console.log(this.error)
            })
            .catch(error => {
                this.is_submitting = false
                this.error = error.response.data.error || null
            })
    },
    fieldHasError(field) {
        return this.error && this.error.hasOwnProperty(field)
    },
    getErrorMessage(field) {
        return this.error && this.error[field]
    }
})
