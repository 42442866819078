import axios from "axios"

export default () => ({
    isSubmitting: false,
    emailWasEntered: false,
    success: false,
    error: false,
    email: '',
    terms: false,
    handleSubmit() {
        if (this.isSubmitting) return

        this.isSubmitting = true
        this.error = false
        this.success = false

        axios
            .post(this.$el.action, this.getFormData())
            .then(response => {
                this.success = response.data
                this.isSubmitting = false
                this.email = ''
                this.terms = false
            })
            .catch(error => {
                this.error = error.response.data
                this.isSubmitting = false
            });
    },
    getFormData() {
        return {
            email: this.email,
            terms_accepted: this.terms,
        };
    },
    resetFormData() {
        this.error = null
        this.success = null
        this.email = ""
        this.terms = false
    },
    get emailIsValid() {
        const re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        return re.test(String(this.email).toLowerCase())
    },
    get submitEnabled() {
        if (this.isSubmitting) return false
        if (!!this.success) return true
        if (!this.terms) return false
        if (!this.emailIsValid) return false

        return true
    },
    get emailHasError() {
        if (this.email != '' && !this.emailIsValid) {
            return true
        }

        if (this.error && this.error.errorType == 'duplicate') {
            return true
        }

        return false
    },
})
