export default (items) => ({
    items: items,
    itemsWidth: 0,
    minimal: false,
    itemsVisible: true,
    currentItemHash: null,
    mobileHeader: null,
    desktopHeader: null,
    sections: [],
    init() {
        this.itemsWidth = this.$refs.items.scrollWidth;
        this.mobileHeader = document.querySelector("#mobile-header");
        this.desktopHeader = document.querySelector("#desktop-header");
        this.setupSections();
        this.toggleMinimal();
        let viewportHalfHeight = window.innerHeight / 2;

        window.addEventListener("resize", () => {
            this.toggleMinimal();
            viewportHalfHeight = window.innerHeight / 2;
        });

        window.addEventListener("scroll", () => {
            this.itemsVisible = false;
            let currentScrollTop = document.documentElement.scrollTop;
            let currentScrollCenter = currentScrollTop + viewportHalfHeight;

            this.sections.forEach(section => {
                let sectionOffsetToViewport = section.element.getBoundingClientRect().top;
                let sectionTopPosition = sectionOffsetToViewport + currentScrollTop;

                if (sectionTopPosition < currentScrollCenter) {
                    this.currentItemHash = section.hash;
                }
            });
        });
    },
    get currentItemTitle() {
        if (this.currentItemHash) {
            return this.items.find(item => item.url === this.currentItemHash).title;
        } else {
            return this.items[0].title;
        }
    },
    toggleMinimal() {
        this.minimal = window.outerWidth < this.itemsWidth;
        this.itemsVisible = !this.minimal;
    },
    toggleItems() {
        this.itemsVisible = !this.itemsVisible;
    },
    scrollTo(sectionId) {
        // If the item starts with a hash, we assume it's a section, otherwise we assume it's a link
        if (sectionId.startsWith("#")) {
            this.$scrollTo(sectionId);
        } else {
            window.open(sectionId, "_blank");
        }
    },
    setupSections() {
        this.items.forEach(item => {
            // Only add items where the url starts with a hash
            if (!item.url.startsWith("#")) {
                return;
            }

            this.sections.push({
                hash: item.url,
                title: item.title,
                element: document.querySelector(item.url),
            });
        });
    },
})
