import Flickity from "flickity"

export default () => ({
    init() {
        let elem = this.$el
        let flkty = new Flickity(elem, {
            cellAlign: "left",
            pageDots: false,
            contain: true,
            draggable: true,
            groupCells: 2,
            wrapAround: true,
        })
    }
})
