import Flickity from "flickity"

export default () => ({
    init() {
        let elem = this.$el
        let flkty = new Flickity(elem, {
            cellAlign: "left",
            pageDots: false,
            contain: true,
            draggable: true,
        })

        // Während der Slider gezogen wird, sollen darin keine Links ausgelöst werden
        flkty.on('dragStart', (event, pointer) => {
            this.$el.classList.add('dragging')
        })
        // Während der Slider gezogen wird, sollen darin keine Links ausgelöst werden
        flkty.on('dragEnd', (event, pointer) => {
            this.$el.classList.remove('dragging')
        })
    }
})
