import Flickity from "flickity"
require('flickity-imagesloaded');

export default () => ({
    init() {
        let elem = this.$el
        let flkty = new Flickity(elem, {
            imagesLoaded: true,
            prevNextButtons: false,
            pageDots: true,
            autoPlay: 2000,
            wrapAround: true,
        })

        // Während der Slider gezogen wird, sollen darin keine Links ausgelöst werden
        flkty.on('dragStart', (event, pointer) => {
            this.$el.classList.add('dragging')
        })
        // Während der Slider gezogen wird, sollen darin keine Links ausgelöst werden
        flkty.on('dragEnd', (event, pointer) => {
            this.$el.classList.remove('dragging')
        })
    }
})
