import axios from "axios"
import AOS from 'aos'
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import mask from '@alpinejs/mask'
import grow from 'alpinejs-textarea-grow'
import smoothscroll from 'smoothscroll-polyfill'
import { CountUp } from "countup.js" // About Animation
import scrollTo from './plugins/scrollTo'
import pageNavigation from './alpine/pageNavigation.js'
import newsletterForm from './alpine/newsletterForm.js'
import cardsSlider from './alpine/cardsSlider.js'
import mediaSlider from './alpine/mediaSlider.js'
import historySlider from './alpine/historySlider.js'
import testimonialsSlider from './alpine/testimonialsSlider.js'
import eventTalks from './alpine/eventTalks.js'
import upcomingEvents from './alpine/upcomingEvents.js'
import sponsorRequestForm from './alpine/sponsorRequestForm.js'
import talentsEventForm from './alpine/talentsEventForm.js'
import talentApplicationForm from './alpine/talentApplicationForm.js'
import studyResearchForm from './alpine/studyResearchForm.js'
import pressForm from './alpine/pressForm.js'
import eveenoEmbed from './alpine/eveenoEmbed.js'
require('particles.js') // Homepage Animation

smoothscroll.polyfill()
window.CountUp = CountUp
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest"

// $(document).ready in vanilla JS
function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(() => {
    AOS.init({
        once: true
    })
})

Alpine.plugin(scrollTo)
Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(mask)
Alpine.plugin(grow)

Alpine.data('pageNavigation', pageNavigation)
Alpine.data('newsletterForm', newsletterForm)
Alpine.data('cardsSlider', cardsSlider)
Alpine.data('mediaSlider', mediaSlider)
Alpine.data('historySlider', historySlider)
Alpine.data('testimonialsSlider', testimonialsSlider)
Alpine.data('eventTalks', eventTalks)
Alpine.data('upcomingEvents', upcomingEvents)
Alpine.data('sponsorRequestForm', sponsorRequestForm)
Alpine.data('talentsEventForm', talentsEventForm)
Alpine.data('talentApplicationForm', talentApplicationForm)
Alpine.data('studyResearchForm', studyResearchForm)
Alpine.data('pressForm', pressForm)
Alpine.data('eveenoEmbed', eveenoEmbed)
Alpine.start()
